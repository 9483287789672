.step-provider .step-order {
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 30px;
  text-align: center;
  line-height: 60px;
  border: 1px dashed #36a6b3;
  margin-right: 10px; }

.step-provider .btn-container {
  margin-top: 50px;
  width: 100%;
  position: fixed;
  z-index: 99;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5.3rem;
  box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.15); }
  .step-provider .btn-container .btn {
    min-width: 120px;
    margin: 0px 5px; }
  .step-provider .btn-container .btn-next {
    color: #fff;
    border-radius: 4px;
    background-color: #36a6b3;
    float: right;
    margin: 0 !important;
    position: absolute;
    right: 7.5rem;
    top: 1.4rem;
    height: 2.8rem;
    padding: 0 1.5rem; }
  .step-provider .btn-container .bold-text {
    color: #4d6366;
    font-weight: 600; }

.step-provider .bg-lightTeal {
  background-color: rgba(54, 166, 179, 0.1); }

.step-provider .order-main {
  position: absolute;
  top: 136px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #f5f8f9; }

.step-provider .step-wrapper {
  height: 100%;
  overflow: scroll; }

.step-provider .step-page {
  margin: 3rem 7.5rem;
  padding: 1.5rem; }

.step-provider .page-title {
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 600;
  text-align: left; }

.step-provider .page-note {
  font-size: 1.5rem;
  text-align: left; }

.step-provider .error-message {
  margin: 1rem;
  position: absolute;
  z-index: 99;
  left: 7.5rem;
  right: 7.5rem;
  width: auto; }

.step-provider .warning-message {
  margin: 1rem;
  position: absolute;
  z-index: 99;
  left: 7.5rem;
  right: 7.5rem; }
  .step-provider .warning-message.alert-container {
    width: auto !important; }
  .step-provider .warning-message .alert {
    margin-bottom: 0; }
  .step-provider .warning-message .close {
    color: #856404 !important;
    position: absolute;
    top: 6px;
    right: 8px; }
  .step-provider .warning-message .title {
    color: #856404 !important; }
  .step-provider .warning-message .message {
    background-color: #fff3cd; }

.step-provider .upload-file,
.step-provider .paid-successfully {
  background-color: #f5f8f9; }

.step-provider .setup-file,
.step-provider .market-selection,
.step-provider .provide-order-info,
.step-provider .checkout-and-pay {
  background-color: #fff; }

.step-provider .Table .setting-select .btn {
  color: inherit; }

.step-provider .Table .row-no {
  text-align: center; }

.step-provider .Table .ReactVirtualized__Table__headerRow {
  color: #6a7d80;
  font-size: 1rem;
  font-weight: bold;
  line-height: 40px; }

.step-provider .Table .ReactVirtualized__Table__row:nth-child(odd) {
  background-color: #e6e9ea; }

.step-provider .Table .ReactVirtualized__Table__row:nth-child(even) {
  background-color: #fff; }

.step-provider .Table .dropdown-toggle::after {
  margin-left: 0.5rem; }

.step-provider .search-input {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  position: relative; }
  .step-provider .search-input i {
    position: absolute;
    color: #a1b0b3;
    font-size: 1rem;
    left: 8px;
    top: 3px; }
  .step-provider .search-input input {
    border: 1px solid #d5dbdc;
    border-radius: 4px;
    padding-left: 2rem;
    height: 34px; }

.step-provider .btn-back {
  height: 40px;
  border: 0;
  color: #364041;
  position: absolute;
  left: 7.5rem;
  background-color: inherit;
  width: 125px; }
  .step-provider .btn-back i {
    font-size: 1.5rem;
    position: absolute;
    left: 14px; }

.step-provider .alert-container {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  min-width: 300px;
  width: 45% !important;
  margin: auto !important; }
  .step-provider .alert-container .title {
    color: #fff; }
    .step-provider .alert-container .title.alert-danger {
      background-color: #ed5f3b;
      border: 0;
      border-radius: 0; }
    .step-provider .alert-container .title .close {
      color: #fff; }
  .step-provider .alert-container .message {
    padding: 0.5rem 1rem; }
