.date-input {
    position: relative;

    i {
        position: absolute;
        left: 8px;
        top: 50%;
        margin-top: -8px;
    }

    input {
        width: 150px;
        min-height: 40px;
        border: 1px solid #a1b0b3;
        border-radius: 4px;
        padding: 0 5px;
        padding-left: 26px;
    }
}