.upload-file .error-message {
  margin: 0;
  margin-top: 1rem; }

.upload-file .page-title {
  font-size: 2.3rem; }

.upload-file .file-formats-section {
  margin-top: 2rem !important; }

.upload-content-box {
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem; }
  .upload-content-box .upload-box {
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    width: 700px;
    height: 350px;
    left: 0;
    right: 0;
    margin: auto; }
  .upload-content-box .drop-box {
    background-color: inherit !important;
    border: 2px dashed #36a6b3 !important; }
    .upload-content-box .drop-box h3 {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: bold; }
    .upload-content-box .drop-box h6 {
      font-size: 1rem; }
    .upload-content-box .drop-box .select-file-box {
      width: 100%;
      height: 100%; }
    .upload-content-box .drop-box .select-file-control {
      padding-top: 80px;
      height: 100%; }
  .upload-content-box .section {
    margin-top: 2rem; }
  .upload-content-box h5 {
    font-size: 1.2rem;
    font-weight: bold; }
  .upload-content-box h3,
  .upload-content-box h5,
  .upload-content-box h6 {
    text-align: center !important; }
  .upload-content-box .read-more-link {
    text-align: center;
    margin-top: 1rem; }
    .upload-content-box .read-more-link a {
      color: #36a6b3;
      font-size: 0.9rem; }
