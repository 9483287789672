.order-summary .small-text {
  font-size: 14px; }

.order-summary .count-text {
  color: #36a6b3;
  font-weight: 600; }

.order-summary .file-info-wrapper {
  margin: 1rem; }
  .order-summary .file-info-wrapper .file,
  .order-summary .file-info-wrapper .info {
    display: inline-block; }
  .order-summary .file-info-wrapper .file {
    margin-right: 1rem; }
  .order-summary .file-info-wrapper i {
    font-size: 2.6rem; }
  .order-summary .file-info-wrapper .file-type {
    font-size: initial;
    top: 1.4rem; }

.order-summary .lang-summary-table .header-cell {
  text-transform: initial; }
