.file-info {
  border: 1px solid #a1b0b3;
  border-radius: 20px;
  padding: 6px 20px;
  display: inline-block;
  margin-top: 1.2rem; }
  .file-info div {
    display: inline-block; }
  .file-info .file-name {
    color: #36a6b3;
    font-size: 14px;
    font-weight: 500; }
  .file-info .icon-download {
    color: #4d6366;
    font-size: 20px;
    margin-left: 24px; }
  .file-info .line {
    margin: 0px 10px; }
  .file-info .line:after {
    content: "";
    border-left: 1px solid #a1b0b3; }
  .file-info .file-delete .icon-delete {
    color: #4d6366;
    font-size: 20px; }
