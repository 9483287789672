.setting-select {
  width: 100%; }
  .setting-select .dropdown-toggle {
    width: 100%;
    text-align: left; }
  .setting-select .dropdown-toggle:after {
    float: right;
    margin-top: 0.6rem; }
  .setting-select.main {
    background-color: #1b7680;
    height: 100%;
    margin-top: -2px;
    color: #fff; }
  .setting-select .btn-group {
    width: 100%; }
    .setting-select .btn-group .btn {
      width: 100%;
      text-align: left; }
  .setting-select .dropdown-menu {
    min-width: 19rem; }
  .setting-select .dropdown-group-item .dropdown-menu {
    max-height: 300px;
    overflow: auto; }
  .setting-select .dropdown-group-item .btn {
    padding: 0.25rem 1.5rem; }
  .setting-select .dropdown-group-item,
  .setting-select .dropdown-item {
    color: #4d6366;
    line-height: 30px; }
    .setting-select .dropdown-group-item.active,
    .setting-select .dropdown-item.active {
      color: #4d6366;
      background-color: initial; }
    .setting-select .dropdown-group-item .icon-selected,
    .setting-select .dropdown-item .icon-selected {
      color: #36a6b3;
      font-size: 14px;
      line-height: 30px;
      margin-left: 1rem;
      float: right; }
  .setting-select .dropdown-group {
    margin-top: 10px;
    padding-left: 1.5rem;
    background-color: #e6e9ea;
    color: #36a6b3;
    font-weight: 600; }
