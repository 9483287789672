.upload-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%; }
  .upload-box .drop-box {
    min-height: 100px;
    min-width: 100px;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    background-color: #f8f9fa;
    margin: 15px;
    border-radius: 10px;
    border: dashed 2px #adb5bd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .upload-box .drop-box .select-file-box {
      text-align: center; }
      .upload-box .drop-box .select-file-box .select-file-control {
        display: block;
        cursor: pointer; }
        .upload-box .drop-box .select-file-box .select-file-control .g-icon svg {
          fill: #adb5bd; }
  .upload-box .dropable .select-file-box .select-file-control {
    color: #36a6b3; }
    .upload-box .dropable .select-file-box .select-file-control .g-icon svg {
      fill: #36a6b3; }

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.saved-cards-pay-button.hide,
.paypal-pay-button.hide {
  height: 0px;
  overflow: hidden; }
  .saved-cards-pay-button.hide .btn,
  .paypal-pay-button.hide .btn {
    display: none; }

.saved-cards-pay-button.show,
.paypal-pay-button.show {
  height: initial;
  overflow: initial; }
  .saved-cards-pay-button.show .btn,
  .paypal-pay-button.show .btn {
    display: inherit; }

@media (max-width: 575.98px) {
  html {
    font-size: 14px; }
    html .checkout-and-pay .page-title {
      font-size: 1.6rem;
      font-weight: 600; }
    html .checkout-and-pay .btn-container .text-success {
      left: 0;
      right: 0; }
    html .checkout-and-pay .btn-container .btn-next {
      left: 0;
      right: 0;
      width: 85%;
      margin: 0 auto !important; }
    html .checkout-and-pay .card-icon {
      margin-right: 0.5rem; }
    html .payment-method img {
      height: 24px !important; }
    html .payment-method .selection {
      min-width: 90px !important; }
    html .payment-method .checkout .btn {
      left: 0;
      right: 0;
      width: 85%;
      margin: 0 auto !important;
      line-height: 40px; } }

body {
  min-width: 1020px;
  overflow-y: hidden;
  font-family: "Open Sans", "Montserrat", "Avenir", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  color: #364041; }

#myConsent,
.cc-revoke {
  opacity: 0.001;
  z-index: -1 !important; }

.app-container {
  width: 100%;
  height: 100%;
  min-width: 992px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto; }

.app-header {
  height: 4.5rem;
  color: #4d6366;
  font-size: 1rem; }

.step-page {
  position: relative; }
  .step-page h1,
  .step-page h2,
  .step-page h3,
  .step-page h4,
  .step-page h5,
  .step-page h6 {
    text-align: left; }
  .step-page h1 {
    margin-top: 15px; }
  .step-page .section {
    margin: 20px 0; }

li {
  list-style: none; }

.container-full {
  margin-left: 7.5rem;
  margin-right: 7.5rem; }

.clickable {
  cursor: pointer; }

.line-1 {
  border: none;
  height: 1px;
  color: #d5dbdc;
  background-color: #d5dbdc; }

.line-2 {
  border: none;
  height: 1px;
  color: #4d6366;
  background-color: #4d6366; }

.link-btn {
  color: #0781c2 !important; }

.btn-primary {
  color: #fff;
  border-radius: 4px;
  background-color: #36a6b3;
  border-color: #36a6b3;
  height: 2.8rem;
  padding: 0 1.5rem; }

.btn-primary:hover {
  color: #fff;
  background-color: #36a6b3;
  border-color: #36a6b3; }

.alert-container.error-alert {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  min-width: 300px;
  width: 45% !important;
  margin: auto !important; }
  .alert-container.error-alert .title {
    color: #fff; }
    .alert-container.error-alert .title.alert-danger {
      background-color: #ed5f3b;
      border: 0;
      border-radius: 0; }
    .alert-container.error-alert .title .close {
      color: #fff; }
  .alert-container.error-alert .message {
    padding: 0.5rem 1rem; }

.flex-centered {
  display: flex;
  align-items: center; }

.page-content-customer {
  position: absolute;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #f5f8f9;
  overflow: scroll; }
  .page-content-customer .page-title {
    font-family: 'Montserrat';
    font-size: 2rem;
    font-weight: 600;
    text-align: left; }
  .page-content-customer .box {
    background-color: #fff;
    position: relative;
    border-radius: 2px; }
  .page-content-customer .content-padding {
    padding: 1.5rem; }
  .page-content-customer .box-content {
    margin-top: 2rem; }
