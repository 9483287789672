.modal {
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  /* Add Animation */ }
  .modal.show {
    display: block; }
  .modal .modal-content {
    animation-name: animatetop;
    animation-duration: 0.4s; }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }
  to {
    top: 0;
    opacity: 1; } }
