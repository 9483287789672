.market-selection {
  margin-bottom: 135px !important; }
  .market-selection .nav-tabs .nav-item {
    margin-bottom: 0px;
    width: 50%;
    text-align: center; }
  .market-selection .nav-tabs .nav-link {
    border: 0;
    position: relative; }
    .market-selection .nav-tabs .nav-link.active {
      color: #36a6b3;
      font-weight: 600; }
      .market-selection .nav-tabs .nav-link.active .active-line {
        height: 6px;
        width: 80px;
        background-color: #36a6b3;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -40px; }
  .market-selection .selection-content {
    margin-top: 1rem; }
  .market-selection .list-view ul {
    padding-left: 0; }
  .market-selection .list-view .lang-selection-list-view {
    border: 1px solid #d5dbdc; }
    .market-selection .list-view .lang-selection-list-view .search-input {
      margin: 1.5rem 1.5rem 3px; }
    .market-selection .list-view .lang-selection-list-view li {
      line-height: 40px; }
      .market-selection .list-view .lang-selection-list-view li div {
        display: inline-block; }
    .market-selection .list-view .lang-selection-list-view .counter {
      display: inline-block;
      width: 55%; }
    .market-selection .list-view .lang-selection-list-view .dropdown-toggle {
      padding: 0 !important; }
    .market-selection .list-view .lang-selection-list-view .dropdown-toggle::after {
      margin-left: 0.5rem; }
  .market-selection .list-view .header {
    line-height: 20px;
    width: 100%;
    display: inline-block; }
    .market-selection .list-view .header .name,
    .market-selection .list-view .header .price-per-lang,
    .market-selection .list-view .header .price-per-word {
      color: #4d6366;
      font-weight: 600;
      float: left; }
  .market-selection .list-view .area {
    margin: 1rem; }
    .market-selection .list-view .area .name {
      width: 55%; }
    .market-selection .list-view .area .price-per-lang,
    .market-selection .list-view .area .price-per-word {
      width: 20%;
      padding-left: 3px; }
    .market-selection .list-view .area .check {
      width: 5%;
      padding-right: 10px; }
      .market-selection .list-view .area .check .checkbox {
        float: right; }
    .market-selection .list-view .area .header {
      line-height: 20px; }
      .market-selection .list-view .area .header .name,
      .market-selection .list-view .area .header .price-per-lang,
      .market-selection .list-view .area .header .price-per-word {
        color: #6A7D80;
        font-weight: bold;
        float: left; }
  .market-selection .list-view .selection-area {
    height: 375px;
    overflow: auto; }
    .market-selection .list-view .selection-area .lang-check {
      width: 100%; }
  .market-selection .list-view .country-flag {
    margin-right: 8px; }
  .market-selection .list-view .select-all {
    padding: 0 1rem;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #dbe0e0; }
    .market-selection .list-view .select-all .lang-check {
      float: right; }
      .market-selection .list-view .select-all .lang-check .name {
        margin-right: 0.5rem; }
    .market-selection .list-view .select-all .check {
      padding-right: 10px; }
      .market-selection .list-view .select-all .check .checkbox {
        display: flex; }
    .market-selection .list-view .select-all .currency-select {
      display: inline-block; }
      .market-selection .list-view .select-all .currency-select .currency-selection {
        display: flex; }
  .market-selection .list-view .check {
    height: 1rem; }
  .market-selection .list-view .country-flag {
    display: none; }
  .market-selection .lang-selection-map-view {
    width: 100%;
    height: 100%; }
  .market-selection .total-section {
    font-size: 0.9rem;
    height: 100%; }
    .market-selection .total-section .section {
      margin: auto; }
      .market-selection .total-section .section.count .value {
        font-size: 2rem;
        font-weight: 600;
        color: #36a6b3; }
      .market-selection .total-section .section.langs {
        border-left: 1px solid #ccc; }
        .market-selection .total-section .section.langs .alert {
          display: inline-block;
          border: 1px solid #a1b0b3;
          border-radius: 20px;
          padding: 6px 14px !important;
          background-color: #fff !important;
          color: #4d6366;
          margin-right: 5px; }
          .market-selection .total-section .section.langs .alert i {
            margin-left: 10px;
            color: #a1b0b3;
            font-size: 12px; }
        .market-selection .total-section .section.langs .selected-langs {
          overflow: auto;
          margin-top: 5px;
          height: 40px; }
    .market-selection .total-section .total {
      margin: auto;
      padding-right: 0; }
      .market-selection .total-section .total .sum .value {
        font-weight: 600;
        font-size: 1.5rem;
        color: #36a6b3; }
      .market-selection .total-section .total .sum .title {
        font-size: 1rem; }
      .market-selection .total-section .total .note {
        color: #829799;
        font-size: 0.8rem; }
