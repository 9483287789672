.payment-page .lang-summary-table ul {
  padding-left: 0;
  margin-bottom: 0; }

.payment-page .lang-summary-table li div {
  display: inline-block; }

.payment-page .lang-summary-table .header {
  color: #6a7d80;
  font-weight: 600;
  overflow: hidden;
  border-bottom: 2px solid #364041; }
  .payment-page .lang-summary-table .header .cell {
    float: left;
    height: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.payment-page .lang-summary-table .body {
  line-height: 40px; }
  .payment-page .lang-summary-table .body li {
    height: 40px;
    line-height: 40px; }
  .payment-page .lang-summary-table .body .lang-summary-item:nth-child(odd) {
    background-color: #e6e9ea; }
  .payment-page .lang-summary-table .body .lang-summary-item:nth-child(even) {
    background-color: #fff; }

.payment-page .lang-summary-table .name {
  width: 43%;
  text-align: left !important;
  padding-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.payment-page .lang-summary-table .count,
.payment-page .lang-summary-table .cost-per-word,
.payment-page .lang-summary-table .total-cost {
  width: 15%; }

.payment-page .lang-summary-table .discount {
  width: 12%; }

.payment-page .lang-summary-table .name {
  padding-right: 0 !important; }

.payment-page .lang-summary-table .cell {
  text-align: right;
  padding-right: 0.5rem;
  overflow: hidden;
  border-right: 1px solid #fff; }
  .payment-page .lang-summary-table .cell .icon {
    float: left;
    margin-left: 0.5rem; }

.payment-page .lang-summary-table .cell:first-child,
.payment-page .lang-summary-table .cell:last-child {
  border-right: none; }
