.qanda {
  z-index: 99; }
  .qanda .qanda-header {
    z-index: 100; }
  .qanda .qanda-list .qanda-list-top {
    z-index: 100; }
  .qanda .qanda-list .ReactVirtualized__List {
    outline: none; }
    .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands {
      visibility: hidden;
      height: 38px;
      right: 20px;
      margin-top: -22px;
      background: white;
      border-radius: 19px; }
      .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands .btn {
        border: none; }
      .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands :last-child {
        border-top-right-radius: 19px;
        border-bottom-right-radius: 19px; }
      .qanda .qanda-list .ReactVirtualized__List .question .reaction-commands :first-child {
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px; }
    .qanda .qanda-list .ReactVirtualized__List .question:hover .reaction-commands {
      visibility: visible; }
    .qanda .qanda-list .ReactVirtualized__List .question .comment .thread-comment {
      margin: auto 0; }
    .qanda .qanda-list .ReactVirtualized__List .question pre {
      white-space: pre-wrap;
      margin-bottom: 0 !important; }

.source-details-view .card {
  margin-bottom: 30px; }

.source-details-view .reaction-commands {
  right: 1rem;
  top: -19px;
  color: #303738;
  font-size: 0.8rem; }

.source-details-view .show {
  color: #36a6b3;
  cursor: pointer;
  font-size: 0.9rem; }

.source-details-view .question-resolved:after {
  font-family: "Font Awesome 5 Free";
  content: "\f560";
  font-weight: 900;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 0.6rem;
  color: green; }

.source-details-view .btn-reply {
  width: 82px;
  height: 38px;
  border-radius: 19px !important;
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  display: none; }

.source-details-view:hover .btn-reply {
  display: block; }
  .source-details-view:hover .btn-reply:disabled {
    background: #dad7d7;
    opacity: 1;
    color: gray; }

.source-details-view .card-body {
  padding: 1.25rem 1.25rem 3rem; }

.source-details-view .author {
  font-weight: 700;
  color: #364041;
  padding-right: 0.5rem;
  font-family: 'Open Sans', sans-serif; }

.source-details-view .comment-content {
  color: #364041;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; }

.source-details-view .avatar-icon {
  margin-right: 0.5rem; }

.source-details-view .thread-comment {
  font-size: 0.9rem;
  color: #364041;
  font-weight: 300; }

.source-details-view .comment-input {
  line-height: 40px; }
  .source-details-view .comment-input .text-input {
    display: inline-block;
    width: auto;
    left: 120px;
    right: 1.5rem;
    position: absolute; }

.source-details-view input {
  border: 1px solid #a1b0b3;
  border-radius: 4px;
  height: 40px;
  width: 100%; }
