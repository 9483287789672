.map-notation {
  font-size: 1rem;
  font-family: inherit !important;
  font-weight: 900; }

.lang-selection-map-view .notes .note-item {
  display: flex;
  align-items: center; }

.lang-selection-map-view .notes .text {
  font-size: 0.9rem;
  margin-left: 1rem; }

.lang-selection-map-view .notes .rect {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 4px; }
  .lang-selection-map-view .notes .rect.done {
    background-color: #36b36b; }
  .lang-selection-map-view .notes .rect.selection {
    background-color: #3651b3; }
  .lang-selection-map-view .notes .rect.unsupport {
    background-color: #b1b1b1; }
  .lang-selection-map-view .notes .rect.not {
    background-color: #d0e8eb; }
