.alert-container {
  overflow: hidden; }
  .alert-container .title {
    padding: 0.5rem 1rem; }
    .alert-container .title .icon-type {
      margin-right: 0.5rem; }
    .alert-container .title.alert-danger {
      border: 0;
      border-radius: 0; }
  .alert-container .message {
    padding: 0.5rem 1rem; }
