.checkout-and-pay {
  margin-bottom: 135px !important; }
  .checkout-and-pay .summary-container {
    margin-top: 2rem; }
    .checkout-and-pay .summary-container .summary .title {
      color: #4d6366;
      font-size: 1.2rem;
      font-weight: bold; }
    @media (min-width: 768px) {
      .checkout-and-pay .summary-container .summary-info-col {
        padding-left: 2rem; } }
  .checkout-and-pay .payment-method {
    font-weight: 600; }
    .checkout-and-pay .payment-method input {
      margin-right: 10px;
      cursor: pointer; }
    .checkout-and-pay .payment-method .selection {
      min-width: 110px;
      display: inline-block;
      cursor: pointer; }
  .checkout-and-pay .payment-summary hr {
    margin: 0.5rem 0; }
  .checkout-and-pay .payment-summary .bold-1 {
    color: #4d6366 !important;
    font-weight: 600; }
  .checkout-and-pay .payment-summary .bold-2 {
    font-size: 1.25rem;
    color: #4d6366 !important;
    font-weight: 600; }
  .checkout-and-pay .payment-summary .label {
    display: inline-block;
    width: 50%;
    color: #a1b0b3;
    font-weight: 600;
    margin: 0.5rem 0; }
  .checkout-and-pay .payment-summary .value {
    display: inline-block;
    width: 50%;
    text-align: right; }
    .checkout-and-pay .payment-summary .value.bold {
      font-size: 1.5rem;
      color: #36a6b3;
      font-weight: 600; }
  .checkout-and-pay .content-container {
    width: 100%;
    margin: 20px 0; }
    .checkout-and-pay .content-container.box {
      border: 1px solid #d5dbdc;
      box-sizing: border-box; }
  .checkout-and-pay .payment-info {
    margin-bottom: 2rem; }
    .checkout-and-pay .payment-info .sign-in-form .submit-btns {
      margin-top: 2rem;
      text-align: right; }
      .checkout-and-pay .payment-info .sign-in-form .submit-btns button {
        float: right;
        width: 200px; }
    .checkout-and-pay .payment-info .sign-in-form .alert {
      top: 38px;
      left: 6rem;
      right: 2.25rem; }
    .checkout-and-pay .payment-info .sign-in-message {
      color: #4d6366;
      font-weight: 600;
      margin-bottom: 1rem; }
    .checkout-and-pay .payment-info .sign-up-help {
      line-height: 50px;
      color: #4d6366;
      font-size: 14px; }
      .checkout-and-pay .payment-info .sign-up-help .btn-text {
        color: #36a6b3;
        font-weight: 500; }
    .checkout-and-pay .payment-info .form-label {
      color: #6a7d80; }
  .checkout-and-pay .payment-currency {
    display: flex;
    min-height: 50px;
    align-items: center; }
    .checkout-and-pay .payment-currency .currency-selection {
      margin-left: 0.5rem;
      font-size: 1.2rem; }
      .checkout-and-pay .payment-currency .currency-selection .btn {
        padding: 0;
        font-size: inherit; }
  .checkout-and-pay .checkout .btn {
    position: fixed;
    margin: 0 !important;
    right: 7.5rem;
    bottom: 1.1rem;
    height: 2.8rem;
    padding: 0 1.5rem;
    color: #fff;
    border-radius: 4px;
    background-color: #36a6b3;
    border: 1px solid #36a6b3;
    z-index: 100;
    min-width: 120px;
    line-height: 43px; }
  .checkout-and-pay .checkout .paypal-button {
    position: absolute;
    left: -1px;
    right: -1px;
    width: auto !important;
    top: -2px;
    bottom: -2px;
    opacity: 0.0001; }
  .checkout-and-pay .checkout .payment-request-button {
    position: absolute;
    height: 46px;
    overflow: hidden;
    left: -1px;
    right: -1px;
    width: auto !important;
    top: -2px;
    bottom: -2px;
    opacity: 0.0001; }
    .checkout-and-pay .checkout .payment-request-button.StripeElement {
      padding: 0; }
  .checkout-and-pay .checkout .visible-text {
    position: absolute;
    left: 0;
    right: 0; }
  .checkout-and-pay .card-icon {
    height: 40px;
    margin-right: 1rem; }
  .checkout-and-pay .total-section {
    height: 100%;
    font-size: 0.9rem; }
    .checkout-and-pay .total-section .file-info {
      float: right;
      margin-top: 1.6rem; }
