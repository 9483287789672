.qna-list .btn-back {
  display: flex;
  margin-top: 1.5rem;
  border: 0;
  color: #364041;
  width: 100px; }
  .qna-list .btn-back i {
    margin-right: 0.8rem;
    font-size: 1.5rem; }

.qna-list .nav-tabs .nav-item {
  margin-bottom: 0px;
  width: 50%;
  text-align: center; }

.qna-list .nav-tabs .nav-link {
  border: 0;
  position: relative !important; }
  .qna-list .nav-tabs .nav-link.active {
    color: #36a6b3;
    font-weight: 600; }
    .qna-list .nav-tabs .nav-link.active .active-line {
      height: 6px;
      width: 80px;
      background-color: #36a6b3;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -40px; }

.qna-list .box {
  margin-top: 3rem;
  height: 700px;
  overflow-y: scroll;
  overflow-x: hidden; }

.qna-list .col-slection-list-view {
  height: 700px;
  overflow-y: scroll; }

.qna-list .col-details-view {
  background-color: #ececec;
  height: 700px;
  overflow-y: scroll; }
  .qna-list .col-details-view .no-question-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .qna-list .col-details-view .no-question-box .no-question-content {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .qna-list .col-details-view .no-question-box .no-question-content img {
        width: 100px; }

.source-selection-list-view ul {
  padding-left: 0; }

.source-selection-list-view .question-select-item {
  padding: 1.5rem 1rem;
  color: #303738;
  font-weight: bold;
  box-shadow: inset 0 -1px 0 0 #d5dbdc;
  position: relative; }
  .source-selection-list-view .question-select-item .card-subtitle {
    display: inline-block;
    color: #303738;
    background-color: #efefef;
    border-radius: 14px;
    padding: 4px 14px; }
  .source-selection-list-view .question-select-item .card-title {
    font-weight: bold;
    color: #303738;
    margin: 1rem 0 0; }
  .source-selection-list-view .question-select-item.select {
    background-color: #d0e8eb; }
  .source-selection-list-view .question-select-item .indicator {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 8px;
    height: 8px;
    background: #ed5f3b;
    border-radius: 50%; }
