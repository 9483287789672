.file-formats {
  text-align: center;
  padding: 0.5rem 0; }

.file-format-icon {
  display: inline-block;
  line-height: 30px;
  border-radius: 5px;
  text-align: center;
  padding: 8px 5px 5px;
  color: #829799;
  position: relative;
  margin-right: 1.5rem; }
  .file-format-icon i {
    font-size: 3.5rem; }
  .file-format-icon .file-type {
    border-radius: 4px;
    color: #fff;
    background-color: #829799;
    font-size: 1.2rem;
    font-weight: 700;
    display: inline-block;
    position: absolute;
    top: 1.9rem;
    line-height: normal;
    width: 100%;
    left: 0;
    text-transform: uppercase; }

.file-format-icon:last-child {
  margin-right: 0; }
