.checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid #4d6366;
  background-color: #fff;
  border-radius: 2px; }
  .checkbox i {
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    font-size: 14px; }
  .checkbox.checked {
    background-color: #3651b3;
    border-color: #3651b3; }
