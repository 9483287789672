.step-provider-header {
  min-height: 64px;
  min-width: 1450px;
  width: 100%;
  background-color: #eaeaea;
  overflow: hidden; }

.step-nav-item {
  line-height: 34px;
  margin: 5px 0; }
  .step-nav-item .content {
    display: inline-block;
    border-radius: 17px;
    border: 1px solid #829799;
    padding: 0 1rem;
    color: #4d6366; }
  .step-nav-item .conector {
    display: inline-block;
    box-sizing: border-box;
    height: 5px;
    width: 30px;
    border-top: 2px dashed #829799; }
  .step-nav-item.done {
    color: #fff; }
    .step-nav-item.done .check-icon {
      display: inline-block; }
    .step-nav-item.done .pending-icon {
      display: none; }
    .step-nav-item.done .content {
      background-color: #36a6b3;
      border: 1px dashed #36a6b3;
      color: #fff; }
    .step-nav-item.done .conector {
      display: inline-block;
      width: 30px;
      border-top: 2px solid #36a6b3; }
  .step-nav-item.active .content {
    border: 1px solid #36a6b3; }
  .step-nav-item .icon {
    margin-right: 8px; }
  .step-nav-item .pending-icon {
    color: #829799; }
  .step-nav-item .check-icon {
    display: none; }
