.provide-order-info {
  margin-bottom: 135px !important; }
  .provide-order-info .checkbox-item.disabled {
    opacity: 0.6;
    pointer-events: none; }
  .provide-order-info .info-container {
    margin-top: 2rem; }
    .provide-order-info .info-container .row {
      margin-bottom: 3rem; }
    .provide-order-info .info-container .drop-box {
      min-height: 40px;
      width: 320px; }
  .provide-order-info .inline .text-input {
    margin-top: -8px;
    margin-left: 1rem; }
    .provide-order-info .inline .text-input input {
      min-width: 200px; }
  .provide-order-info .text-input textarea,
  .provide-order-info .text-input input {
    min-width: 20rem;
    border: 1px solid #a1b0b3;
    border-radius: 4px; }
  .provide-order-info .text-input input {
    height: 40px; }
  .provide-order-info .text-input textarea:disabled,
  .provide-order-info .text-input input:disabled {
    opacity: 0.6; }
  .provide-order-info label .text-input {
    margin-top: 0.75rem; }
    .provide-order-info label .text-input.flex-centered .checkbox-item:not(:first-child) {
      margin-left: 1.5rem; }
  .provide-order-info label .date-input {
    margin-top: 0.75rem; }
    .provide-order-info label .date-input input {
      width: 150px; }
  .provide-order-info label .note-text {
    margin-top: 8px; }
  .provide-order-info .select {
    margin-top: 12px;
    min-width: 320px;
    max-width: 320px; }
    .provide-order-info .select.multi {
      max-width: initial; }
  .provide-order-info .page-title.uploading {
    padding-top: 0; }
  .provide-order-info .page-note {
    margin: 0rem 0 1rem 0;
    color: #4d6366; }
  .provide-order-info section {
    margin: 1rem 0 !important; }
  .provide-order-info .label-text {
    color: #4d6366;
    font-weight: 600; }
  .provide-order-info .required-text {
    color: #cc5050;
    font-size: 0.75rem;
    font-style: italic; }
  .provide-order-info .note-text {
    font-size: 0.9rem; }
  .provide-order-info .game-title .label {
    display: block; }
  .provide-order-info .game-title .text-input {
    margin-top: 1rem; }
  .provide-order-info .game-release-date input {
    width: 165px;
    min-width: initial;
    color: #4d6366; }
  .provide-order-info .checkbox-item label {
    margin-left: 8px;
    font-weight: 400;
    color: #4d6366; }
  .provide-order-info .checkbox-item .checkbox {
    top: 3px; }
  .provide-order-info .checkbox {
    border-color: #a1b0b3; }
    .provide-order-info .checkbox.checked {
      background-color: #36a6b3;
      border-color: #36a6b3; }
  .provide-order-info .upload-box {
    display: inherit;
    width: 100%;
    margin-top: 0.75rem; }
    .provide-order-info .upload-box .upload-box {
      width: 100%; }
    .provide-order-info .upload-box .select-file-box {
      width: 100%;
      height: 100%; }
      .provide-order-info .upload-box .select-file-box label {
        margin-bottom: 0;
        height: 100%; }
    .provide-order-info .upload-box h3,
    .provide-order-info .upload-box h6 {
      text-align: center; }
  .provide-order-info .asset-files .file-info {
    margin-right: 1rem; }
  .provide-order-info .note .text-input {
    margin-top: 0.75rem; }
  .provide-order-info .note .note-guide {
    color: #829799;
    font-weight: 400i;
    font-style: italic;
    font-size: 0.75rem; }
  .provide-order-info hr {
    margin: 3.5rem 0; }
  .provide-order-info .total-section {
    height: 100%;
    font-size: 0.9rem; }
    .provide-order-info .total-section .section {
      margin: auto; }
      .provide-order-info .total-section .section.count .value {
        font-size: 2rem;
        font-weight: 600;
        color: #36a6b3; }
    .provide-order-info .total-section .total {
      margin: auto;
      padding-right: 0; }
      .provide-order-info .total-section .total .sum .value {
        font-weight: 600;
        font-size: 1.5rem;
        color: #36a6b3; }
      .provide-order-info .total-section .total .sum .title {
        font-size: 1rem; }
      .provide-order-info .total-section .total .note {
        color: #829799;
        font-size: 0.8rem; }
