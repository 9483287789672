.user-nav {
  margin-left: auto; }
  .user-nav .auth-group button {
    margin-left: 1rem;
    text-transform: uppercase;
    min-width: 110px;
    min-height: 40px;
    color: #4d6366;
    border: 1px solid #1b7680;
    border-radius: 4px; }
  .user-nav .btn-user-info {
    min-width: 120px;
    min-height: 40px;
    color: #4d6366;
    border: 1px solid #1b7680;
    border-radius: 20px; }
  .user-nav .btn-signup {
    border-color: #36a6b3 !important;
    background-color: #36a6b3 !important;
    color: #fff !important; }
  .user-nav .dropdown-menu {
    margin-top: 10px; }
    .user-nav .dropdown-menu .dropdown-item {
      padding: 0.5rem 1.5rem; }
    .user-nav .dropdown-menu .btn-logout {
      border: 0; }
  .user-nav .btn-group {
    margin-left: 1rem; }
    .user-nav .btn-group button {
      padding: 0 1rem; }
  .user-nav .btn-user-info {
    text-transform: initial; }
    .user-nav .btn-user-info i {
      margin-right: 0.5rem; }
  .user-nav .dropdown-toggle::after {
    margin-left: 1rem; }

.navbar .navbar-toggler {
  position: absolute;
  left: 190px;
  top: 24px; }
