.app-header .logo {
  height: 48px; }

.app-header .app-name {
  font-size: 14px;
  font-weight: bolder;
  color: #36a6b3; }

.app-header .nav-item {
  margin: 0 1rem; }

.app-header .nav-link.active {
  color: #364041;
  font-weight: bold; }

.app-header .active-line {
  height: 4px;
  background-color: #36a6b3;
  margin: 0 25%; }
