.qnacell {
  position: relative; }
  .qnacell .far {
    color: #364041; }
  .qnacell .indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red; }
