.currency-selection {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 2px 8px; }
  .currency-selection .dropdown-menu {
    min-width: 6rem; }
  .currency-selection .dropdown-item {
    color: #4d6366;
    display: flex;
    align-items: center;
    line-height: initial; }
    .currency-selection .dropdown-item.active {
      color: #4d6366;
      background-color: initial; }
    .currency-selection .dropdown-item .icon-selected {
      color: #36a6b3;
      font-size: 14px;
      margin-left: 1rem; }
  .currency-selection .btn {
    color: inherit;
    font-weight: inherit;
    display: flex;
    align-items: center; }
