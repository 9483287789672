.setup-file {
  margin-bottom: 135px !important; }
  .setup-file .page-note {
    padding-bottom: 1rem; }
  .setup-file .copy-settings {
    padding-top: 1.5rem; }
    .setup-file .copy-settings .btn-copy-settings {
      border: 1px solid #36a6b3;
      border-radius: 4px;
      color: #36a6b3;
      font-weight: 500; }
    .setup-file .copy-settings .label {
      margin-left: 0.5rem; }
  .setup-file .file-setting-row {
    margin-top: 1rem; }
    .setup-file .file-setting-row .nav-tabs {
      border: none; }
      .setup-file .file-setting-row .nav-tabs .nav-item {
        background-color: #e6e9ea;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        margin-bottom: 0;
        position: relative;
        margin-right: 1px; }
      .setup-file .file-setting-row .nav-tabs .nav-link {
        padding: 0.5rem 1.8rem; }
        .setup-file .file-setting-row .nav-tabs .nav-link.active {
          color: #fff;
          background-color: #36a6b3;
          border: transparent; }
          .setup-file .file-setting-row .nav-tabs .nav-link.active i {
            color: #fff; }
      .setup-file .file-setting-row .nav-tabs i {
        position: absolute;
        top: 1rem;
        left: 0.5rem;
        color: #36a6b3;
        font-size: 0.8rem; }
  .setup-file .btn-container .file-info {
    margin-top: 1.5rem; }

.raw-file {
  color: initial;
  font-size: initial;
  border: 1px solid #cdcdcd;
  padding: 1rem;
  height: 440px;
  font-family: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New"; }
