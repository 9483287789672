.order-list {
  margin-top: 7rem; }
  .order-list .file-download {
    color: #36a6b3; }
  .order-list .qa-btn {
    font-size: 1.2rem; }
  .order-list .table-container {
    overflow: initial; }
  .order-list .pagination .page-link {
    color: #36a6b3; }
  .order-list .pagination .page-item.active > .page-link {
    height: 100%;
    width: 100%;
    background: #36a6b3;
    border: #36a6b3;
    color: #fff; }
  .order-list .Table {
    border-left: 1px solid #e0e0e0; }
    .order-list .Table .ReactVirtualized__Table__headerRow {
      border-bottom: 2px solid #364041;
      border-top: 1px solid #e0e0e0; }
    .order-list .Table .header-cell {
      margin: 0;
      border-right: 1px solid #e0e0e0;
      height: 100%;
      text-transform: none;
      color: #6A7D80;
      font-size: 1rem;
      font-weight: bold;
      line-height: 40px;
      padding-left: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .order-list .Table .cell {
      line-height: 30px;
      height: 40px;
      width: 100%;
      padding: 5px 5px 5px 1rem;
      text-overflow: ellipsis;
      overflow: hidden; }
    .order-list .Table .ReactVirtualized__Table__row {
      border-bottom: 1px solid #e0e0e0; }
    .order-list .Table .ReactVirtualized__Table__headerRow {
      color: #6A7D80;
      font-size: 1rem;
      font-weight: bold;
      line-height: 40px; }
    .order-list .Table .ReactVirtualized__Table__row:nth-child(odd) {
      background-color: #e6e9ea; }
    .order-list .Table .ReactVirtualized__Table__row:nth-child(even) {
      background-color: #fff; }
    .order-list .Table .cell {
      height: 100%;
      border-right: 1px solid #e0e0e0;
      margin: 0px; }
      .order-list .Table .cell .cell-content {
        line-height: 30px;
        height: 40px;
        width: 100%;
        padding: 5px;
        text-overflow: ellipsis;
        overflow: hidden; }
  .order-list .btn-qna-continue-order {
    float: right;
    line-height: 2.8rem; }
