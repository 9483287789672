.paid-successfully {
  margin-top: 0 !important;
  top: 74px !important; }
  .paid-successfully .step-page {
    background-color: #fff;
    text-align: center;
    padding: 3.75rem 0; }
  .paid-successfully .icon-done {
    width: 100px;
    height: 100px;
    background: url("/img/icon_done.svg") no-repeat;
    background-size: contain;
    display: inline-block; }
  .paid-successfully .page-title {
    padding-top: 1.5rem; }
  .paid-successfully .page-note {
    margin: 1.5rem 0 3rem 0; }
  .paid-successfully button {
    padding-top: 1.5rem;
    color: #fff !important;
    border-radius: 4px;
    background-color: #36a6b3;
    margin: 0 !important;
    height: 2.8rem;
    padding: 0 1.5rem; }
  .paid-successfully .text-btn {
    color: #36a6b3;
    font-weight: 500;
    font-size: 14px; }
