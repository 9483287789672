.user-profile .label {
  color: #4d6366;
  font-weight: 600; }

.user-profile .value {
  margin-bottom: 15px;
  color: #4d6366; }

.user-profile .text-btn {
  color: #36a6b3;
  font-weight: 500;
  font-size: 14px; }
