.another-react-popover-container,
.popover {
  background: transparent !important;
  border: 0 !important;
  margin-top: 34px; }
  .another-react-popover-container > div > div:nth-child(2),
  .popover > div > div:nth-child(2) {
    background-color: #36a6b3 !important; }
  .another-react-popover-container .content,
  .popover .content {
    padding: 20px;
    min-width: 200px;
    min-height: 100px; }
