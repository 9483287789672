.setting-table {
  min-height: 440px; }
  .setting-table .Table {
    border-left: 1px solid #e0e0e0; }
    .setting-table .Table .ReactVirtualized__Table__headerRow {
      border-bottom: 2px solid #364041;
      border-top: 1px solid #e0e0e0; }
    .setting-table .Table .header-cell {
      margin: 0;
      border-right: 1px solid #e0e0e0;
      height: 100%;
      text-transform: none; }
      .setting-table .Table .header-cell .country-flag {
        margin-right: 8px; }
      .setting-table .Table .header-cell .dropdown-group-item .dropdown-toggle .country-flag {
        display: none; }
    .setting-table .Table .setting-select-cell {
      margin-top: -2px; }
    .setting-table .Table .ReactVirtualized__Table__row {
      border-bottom: 1px solid #e0e0e0; }
    .setting-table .Table .start-translate {
      border-top: 4px solid #1b7680;
      padding-top: 1px !important; }
    .setting-table .Table .cell {
      height: 100%;
      border-right: 1px solid #e0e0e0;
      margin: 0px; }
      .setting-table .Table .cell .cell-content {
        line-height: 30px;
        height: 40px;
        width: 100%;
        padding: 5px;
        text-overflow: ellipsis;
        overflow: hidden; }
    .setting-table .Table .dropable {
      background-color: #d0e8eb; }
    .setting-table .Table .dropdown-item .country-flag {
      margin-right: 8px; }
    .setting-table .Table .start-line {
      opacity: 0.9;
      border-radius: 12px;
      background-color: #1b7680;
      color: #fff;
      font-size: 14px;
      text-align: center;
      padding: 0px 10px;
      position: fixed;
      left: 40px;
      margin-top: -10px; }
    .setting-table .Table .ReactVirtualized__Table__row:nth-child(1) .start-line {
      margin-top: 0; }

.popover {
  max-width: inherit !important;
  z-index: 9 !important; }

.setting-tip {
  color: #fff;
  font-family: "Montserrat" !important;
  font-size: 1rem;
  width: 320px; }
  .setting-tip button {
    margin-top: 20px;
    width: 100%;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff; }
