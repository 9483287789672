.auth-modal {
  overflow: scroll !important; }
  .auth-modal.show {
    display: block; }
  .auth-modal .modal-dialog {
    max-width: 800px; }
  .auth-modal .modal-content {
    border-radius: 12px; }
  .auth-modal.sign-in-modal .modal-dialog, .auth-modal.forgot-password-modal .modal-dialog {
    max-width: 500px; }
  .auth-modal.sign-in-modal .modal-content, .auth-modal.forgot-password-modal .modal-content {
    width: 500px; }
  .auth-modal .modal-header {
    line-height: 2rem;
    border-bottom: 0; }
    .auth-modal .modal-header button {
      text-align: right;
      color: #4d6366;
      font-size: 1.5rem; }
  .auth-modal .modal-body {
    margin: 0 1.25rem; }
  .auth-modal .title {
    color: #4d6366;
    font-size: 2rem;
    font-weight: 600; }
  .auth-modal .note {
    color: #829799;
    font-size: 0.8rem;
    margin-top: -0.8rem;
    margin-bottom: 0.5rem; }
  .auth-modal .sign-in-form,
  .auth-modal .sign-up-form,
  .auth-modal .edit-profile-form,
  .auth-modal .forgot-password-form,
  .auth-modal .change-password-form {
    margin-top: 1.5rem; }
    .auth-modal .sign-in-form form,
    .auth-modal .sign-up-form form,
    .auth-modal .edit-profile-form form,
    .auth-modal .forgot-password-form form,
    .auth-modal .change-password-form form {
      margin-top: 1rem; }
    .auth-modal .sign-in-form .sign-in-help,
    .auth-modal .sign-in-form .sign-up-help,
    .auth-modal .sign-up-form .sign-in-help,
    .auth-modal .sign-up-form .sign-up-help,
    .auth-modal .edit-profile-form .sign-in-help,
    .auth-modal .edit-profile-form .sign-up-help,
    .auth-modal .forgot-password-form .sign-in-help,
    .auth-modal .forgot-password-form .sign-up-help,
    .auth-modal .change-password-form .sign-in-help,
    .auth-modal .change-password-form .sign-up-help {
      margin-top: 1rem;
      color: #4d6366;
      font-size: 14px; }

.sign-in-form .form-label,
.sign-up-form .form-label,
.edit-profile-form .form-label,
.forgot-password-form .form-label,
.change-password-form .form-label {
  padding: 10px 0 0;
  display: block;
  font-weight: 600; }
  .sign-in-form .form-label span,
  .sign-up-form .form-label span,
  .edit-profile-form .form-label span,
  .forgot-password-form .form-label span,
  .change-password-form .form-label span {
    color: #829799;
    font-size: 12px;
    font-style: italic;
    font-weight: initial; }

.sign-in-form .row:first-child,
.sign-up-form .row:first-child,
.edit-profile-form .row:first-child,
.forgot-password-form .row:first-child,
.change-password-form .row:first-child {
  margin-top: 1rem; }

.sign-in-form .form-input,
.sign-up-form .form-input,
.edit-profile-form .form-input,
.forgot-password-form .form-input,
.change-password-form .form-input {
  height: 40px;
  width: 100%;
  border: 1px solid #829799;
  border-radius: 4px;
  padding: 0 0.5rem; }

.sign-in-form .submit-btns,
.sign-up-form .submit-btns,
.edit-profile-form .submit-btns,
.forgot-password-form .submit-btns,
.change-password-form .submit-btns {
  margin-top: 3.75rem;
  margin-bottom: 1rem; }
  .sign-in-form .submit-btns button,
  .sign-up-form .submit-btns button,
  .edit-profile-form .submit-btns button,
  .forgot-password-form .submit-btns button,
  .change-password-form .submit-btns button {
    height: 46px;
    width: 100%;
    border-radius: 4px;
    background-color: #36a6b3;
    color: #fff;
    font-weight: 500; }
  .sign-in-form .submit-btns .btn-signup,
  .sign-up-form .submit-btns .btn-signup,
  .edit-profile-form .submit-btns .btn-signup,
  .forgot-password-form .submit-btns .btn-signup,
  .change-password-form .submit-btns .btn-signup {
    width: 150px; }

.sign-in-form .error .input-feedback,
.sign-up-form .error .input-feedback,
.edit-profile-form .error .input-feedback,
.forgot-password-form .error .input-feedback,
.change-password-form .error .input-feedback {
  color: #ed5f3b;
  font-size: 14px; }

.sign-in-form .alert,
.sign-up-form .alert,
.edit-profile-form .alert,
.forgot-password-form .alert,
.change-password-form .alert {
  position: absolute;
  top: 0px;
  left: -1.25rem;
  right: -1.25rem;
  height: 40px;
  padding-left: 2.25rem;
  border-radius: 0;
  background-color: #ed5f3b;
  color: #fff;
  font-size: 14px; }
  .sign-in-form .alert i,
  .sign-up-form .alert i,
  .edit-profile-form .alert i,
  .forgot-password-form .alert i,
  .change-password-form .alert i {
    padding-right: 0.5rem; }

.sign-in-form .text-btn,
.sign-in-form .btn-link,
.sign-up-form .text-btn,
.sign-up-form .btn-link,
.edit-profile-form .text-btn,
.edit-profile-form .btn-link,
.forgot-password-form .text-btn,
.forgot-password-form .btn-link,
.change-password-form .text-btn,
.change-password-form .btn-link {
  color: #36a6b3;
  font-weight: 500; }

.sign-up-form .read-more-link a,
.edit-profile-form .read-more-link a {
  color: #36a6b3;
  font-size: 14px;
  font-weight: 500; }

.sign-up-form .submit-btns,
.edit-profile-form .submit-btns {
  text-align: right; }

.edit-profile-form .submit-btns {
  margin-top: 2.75rem; }

.sign-up-form .submit-btns {
  margin-top: 2rem; }

.clickable {
  cursor: pointer; }

.logo-icon {
  width: 126px;
  height: 40px;
  background-image: url(/img/logo_ld_small.png);
  background-size: cover; }

.country-select .select > div {
  border: 1px solid #829799; }

.password-wrapper {
  position: relative; }
  .password-wrapper .forgot-btn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    padding: 11px 0; }

.policy-consent {
  font-size: 0.9rem;
  text-align: center;
  margin-top: 2rem; }
  .policy-consent span {
    width: 100%; }
